import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout.js"

import "./blog.css"

const Blog = ({ data }) => {
  const blogs = data.allMarkdownRemark.edges
  return (
    <Layout>
      <div className="blog-main" id="blog">
        <h1 className="header">Michael's Musings</h1>
        <hr className="header-hr" />
        {blogs.map((item, index) => {
          const blog = item.node.frontmatter
          const featuredImgFluid = blog.featuredImage.childImageSharp.fluid

          return (
            <div className="blog-container" key={index}>
              <div className="wrapper">
                <div className="blog-image-container">
                  <Img className="featured-image" fluid={featuredImgFluid} />
                </div>
                <div className="blog-description-container">
                  <Link
                    component="h2"
                    to={`${blog.path}`}
                    className="blog-header"
                  >
                    {blog.title}
                  </Link>
                  <div className="blog-date">{blog.date}</div>
                  <div className="blog-abstract">{blog.abstract}</div>
                </div>
              </div>
              <hr className="blog-divider" />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/Posts/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            path
            title
            abstract
            featuredImage {
              childImageSharp {
                fluid(quality: 80, maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
